import React, { useEffect } from "react"
import { useMutation } from "@apollo/react-hooks"
import { Paragraph, Anchor } from "grommet"

import ProConnectStatus from "./ProConnectStatus"

import CONNECT_PRO from "../queries/connectPro"

const StripeConnect = ({ code, state }) => {
  const [sendConnection, { data, loading }] = useMutation(CONNECT_PRO)

  useEffect(() => {
    sendConnection({ variables: { code, state } })
  }, [code, sendConnection, state])

  if (!code || !state) {
    return (
      <Paragraph>
        We could not find your account. Please check your Stripe Connect link in
        the email that was sent or contact{" "}
        <Anchor href="mailto:support@backdrophome.com">
          support@backdrophome.com
        </Anchor>
        .
      </Paragraph>
    )
  }

  return (
    <>
      {loading ? (
        <Paragraph>Loading...</Paragraph>
      ) : (
        <ProConnectStatus state={state} />
      )}
    </>
  )
}

export default StripeConnect
