import React from "react"
import { Box } from "grommet"

import Layout from "../../components/layout"
import Heading from "../../components/Heading"
import ResponsiveInnerPad from "../../components/ResponsiveInnerPad"
import ConstrainedContainer from "../../components/ConstrainedContainer"
import StripeConnect from "../../components/StripeConnect"
import withLocation from "../../components/withLocation"

const ConnectPage = ({ search }) => (
  <Layout>
    <ConstrainedContainer>
      <ResponsiveInnerPad>
        <Heading level={1}>Industry by Backdrop</Heading>
        <Box pad={{ vertical: "large" }}>
          <StripeConnect code={search.code} state={search.state} />
        </Box>
      </ResponsiveInnerPad>
    </ConstrainedContainer>
  </Layout>
)

export default withLocation(ConnectPage)
