import gql from "graphql-tag"

const PRO_CONNECT_STATUS = gql`
  query ProConnectStatus($state: String!) {
    proConnectStatus(state: $state) {
      stripe_account_id
      stripe_errors {
        message
        displayMessage
      }
    }
  }
`

export default PRO_CONNECT_STATUS
