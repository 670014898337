import React from "react"
import { useQuery } from "@apollo/react-hooks"
import { Paragraph, Anchor } from "grommet"

import PRO_CONNECT_STATUS from "../queries/proConnectStatus"

const ProConnectStatus = ({ state }) => {
  const { loading, data, stopPolling } = useQuery(PRO_CONNECT_STATUS, {
    variables: { state },
    pollInterval: 1000,
  })

  if (loading || !data) {
    return <Paragraph>Setting up your account...</Paragraph>
  }
  const isConnected =
    data.proConnectStatus &&
    data.proConnectStatus.stripe_account_id &&
    data.proConnectStatus.stripe_account_id !== ""
  if (isConnected) {
    stopPolling()
  }

  const errors = data.proConnectStatus
    ? data.proConnectStatus.stripe_errors
    : []

  return (
    <>
      {(errors || []).length ? (
        <>
          {errors.map(x => (
            <Paragraph color="critical" key={x.message}>
              {x.displayMessage}
            </Paragraph>
          ))}
          <Paragraph color="critical">
            Please contact{" "}
            <Anchor href="mailto:support@backdrophome.com">
              support@backdrophome.com
            </Anchor>
          </Paragraph>
        </>
      ) : (
        <>
          {isConnected ? (
            <Paragraph>Your account has been successfully set up.</Paragraph>
          ) : (
            <Paragraph>Setting up your account...</Paragraph>
          )}
        </>
      )}
    </>
  )
}

export default ProConnectStatus
