import gql from "graphql-tag"

const CREATE_PRO = gql`
  mutation ConnectPro($code: String!, $state: String!) {
    connectPro(input: { code: $code, state: $state }) {
      is_connected
      errors {
        message
        displayMessage
      }
    }
  }
`

export default CREATE_PRO
